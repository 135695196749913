import React, { useContext } from "react";
import classes from "./ErrorCard.module.css";
import ImageImports from "../../utils/ImageImports";
import { Link } from "react-router-dom";
import { Store } from "../../Store";

const ErrorCard = (props: ErrorCardProps) => {
  const { setShowError } = useContext(Store);
  const { dangerIcon, closeError, alarmWarning, success, info } = ImageImports;

  const handleCloseError = () => {
    setShowError &&
      setShowError({
        title: "",
        isError: false,
        ErrorType: null
      });
  };

  return (
    <div className={`${classes.errorCard} ${props.ErrorType === "danger" ? classes.danger : props.ErrorType === "info" ? classes.info : props.ErrorType === "warning" ? classes.warning : classes.success}`}>
      <div className={classes.errorCardInner}>
        <span className={`${classes.icon} fill-current w-6 h-6 mr-8`}>
          <img src={props.ErrorType === "danger" ? dangerIcon : props.ErrorType === "info" ? info : props.ErrorType === "warning" ? alarmWarning : success} alt="" />
        </span>
        <span className={`${classes.errorMsg} block sm:inline`}>{`${props.ErrorMessage}`}</span>
        {props.onRefresh && props.ErrorType === "danger" && (
          <Link className={classes.link} onClick={props.onRefresh} to={"#"}>
            Try Refresh
          </Link>
        )}
      </div>
      {props.closeBtn && (
        <Link to={"#"} onClick={handleCloseError} className={classes.link}>
          <img src={closeError} alt="" />
        </Link>
      )}
    </div>
  );
};

interface ErrorCardProps {
  ErrorType: "danger" | "warning" | "success" | "info" | null;
  ErrorMessage: string;
  closeBtn: boolean;
  onRefresh?(): void;
}

ErrorCard.defaultProps = {
  closeBtn: true
};
export default ErrorCard;
