import Button from "../../components/Button/Button";
import { MainContainer } from "../MainContainer";
import { getKpiReport } from "../Support/ESR/ESRHttpServices";
import { DocumentGroup } from "../../utils/interface";
import { usePageRequiresAdmin } from "../../customHooks/usePageRequiresAdmin";
import { downloadFileByUrl } from "../../customHooks/FileHttpServices";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { CrudMethods, MitsubishiPowerReports, OmManuals, TechnicalBulletins } from "../TechnicalDocumentation/documentGroupApiMethods";
import { ModuleIDs } from "../../utils/moduleIDs";
import { useEffect, useState } from "react";
import { SelectOptionInterface } from "./BulkUpload/BulkTechnicalDocumentModuleContainer";
import BulkAddDocumentForm from "./BulkUpload/BulkAddDocumentForm";

const bulkDocumentModules: BulkDocumentModuleInterface[] = [
  {
    id: ModuleIDs.MitsubishiPowerReports,
    name: "Mitsubishi Power Reports",
    route: "power-report",
    btnText: "Bulk Upload Power Reports",
    enabled: true,
    addEditHeaderName: "a Mitsubishi Power Report",
    addDocumentModalBodyContent: BulkAddDocumentForm,
    documentGroupApi: MitsubishiPowerReports,
    addModalHeaderContent: []
  },
  {
    id: ModuleIDs.TechnicalBulletins,
    name: "Technical Bulletins",
    route: "technical-bulletin",
    btnText: "Bulk Upload Technical Bulletins",
    enabled: false,
    addEditHeaderName: "a Mitsubishi Power Technical Document",
    addDocumentModalBodyContent: BulkAddDocumentForm,
    documentGroupApi: TechnicalBulletins,
    addModalHeaderContent: []
  },
  {
    id: ModuleIDs.OMManuals,
    name: "O&M Manuals",
    route: "om-manual",
    enabled: true,
    btnText: "Bulk Upload O&M Manuals",
    addEditHeaderName: "a Mitsubishi Power O&M Manual",
    addDocumentModalBodyContent: BulkAddDocumentForm,
    documentGroupApi: OmManuals,
    addModalHeaderContent: [
      "If you are looking to add an O&M manual document that contains multiple chapter content, please name the file accordingly before uploading. Users will be able to see, browse and navigate the embedded table of contents within the document once they’ve opened the file in their PDF file viewer.",
      "You may assign each document upload a “Section” tag, which will communicate the content type to customers."
    ]
  }
];

export interface BulkAddDocumentRequiredProps {
  onSave: (data: any) => void;
  documentGroups: DocumentGroup[];
  productTypes: SelectOptionInterface[];
  omSections: SelectOptionInterface[];
  documentModule: BulkDocumentModuleInterface;
  isLoading?: {
    isAdding?: boolean;
  };
}

export interface BulkDocumentModuleInterface {
  name: string;
  id: number;
  route: string;
  btnText: string;
  enabled: boolean;
  addEditHeaderName: string;
  addModalHeaderContent: string[];
  addDocumentModalBodyContent: React.FunctionComponent<BulkAddDocumentRequiredProps>;
  documentGroupApi: CrudMethods<any, any, any>;
}

const DataReportingContainer = () => {
  usePageRequiresAdmin();
  const [module, setModule] = useState<BulkDocumentModuleInterface | null>(null);
  const { moduleName } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const module = bulkDocumentModules.find((module) => module.route === moduleName) || null;
      setModule(module);
    })();
  }, [moduleName]);

  const getAllPlantKpiData = async () => {
    downloadFileByUrl(getKpiReport());
  };

  return (
    <MainContainer breadCrumbs={["Administration", "Data & Reporting"]} title="Data & Reporting">
      <div className="flex flex-col">
        <div className="flex flex-row items-start gap-6 self-stretch">
          <div className="flex flex-col items-start gap-2 grow" style={{ flex: "2 1 0%" }}>
            <span className="font-bold text-2xl self-stretch">Data & Reporting</span>
            <span className="text-base">Click here to view system-generated reports regarding plants and other information.</span>
          </div>
          <div className="flex flex-row gap-4" style={{ flex: "1 1 0%" }}>
            <Button className="darkBlue" text="Generate Report" style={{ marginLeft: "auto" }} onClick={getAllPlantKpiData} />
          </div>
        </div>
        {bulkDocumentModules.filter((x) => x.enabled).length > 0 && (
          <div className="flex mt-10">
            <div className="flex flex-col w-full">
              <h2 className="font-bold text-2xl self-stretch">Bulk Upload</h2>
              <div className="flex mt-2 mb-2 gap-5 items-center w-full">
                <span className="text-base">Bulk upload a single Technical Document to multiple Plant Sites.</span>
              </div>
              <div className="flex mt-2 mb-2 gap-5 items-center w-full">
                {bulkDocumentModules
                  .filter((x) => x.enabled)
                  .map((module, i) => (
                    <Button key={i} className="darkBlue" text={module.btnText} onClick={() => navigate(`${location.pathname}/${module.route}/add`)} />
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <Outlet context={{ documentModules: bulkDocumentModules, documentModule: module }} />
    </MainContainer>
  );
};

export default DataReportingContainer;
