import { AxiosError } from "axios";
import React, { ReactElement, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useGet_AccountSettings, useGet_HeaderData } from "../../containers/PlantInformation/DashboardHttpServices";
import { usePlantConfigData } from "../../containers/PlantInformation/PlantConfigHttpServices";
import { useOnError } from "../../customHooks/useOnError";
import useWindowSize from "../../customHooks/useWindowSize";
import { useStore } from "../../useStore";
import ImageImports from "../../utils/ImageImports";
import { AccountSettingsDTO, HeaderDTO } from "../../utils/interface";
import Dropdown from "../Dropdown/Dropdown";
import MobileMenu from "../MobileMenu/MobileMenu";
import Notification from "../Notification/Notification";
import { useGetNotificationTypes } from "../NotificationPane/NotificationHttpServices";
import Tooltip from "../PopoutTooltip/Tooltip";
import SearchGlobal from "../Search/SearchGlobal";
import AccountSettingsModal from "./AccountSettingsModal";
import "./Navbar.css";
import SignOutModal from "./SignOutModal";

function Navbar({ headerHeight }: NavbarProps) {
  const { logo, bellIcon, menuOpenIcon } = ImageImports;
  const { isDesktop } = useWindowSize();
  const queryClient = useQueryClient();
  const {
    setHeader,
    setNotificationTypes,
    setAccountSettings,
    accountSettings: { data: accountSettings },
    mobileNavToggle,
    toggleMobileNav,
    header: { data },
    setSelectedPlant,
    setPlantConfig,
    setShowError,
    selectedPlant,
  } = useStore();
  const [selectedOption, setSelectedOption] = useState<string | null>("");
  const [paramsPlantID, setParamsPlantID] = useState(0);
  const [plantIDParams, setPlantIDParams] = useSearchParams();
  const [showAccountMenu, setShowAccountMenu] = useState<boolean>(false);
  const [modal, setModal] = useState<ReactElement | undefined>();

  const location = useLocation();

  const modals = {
    account: <AccountSettingsModal headerData={data} closeModal={() => setModal(undefined)} />,
    signOut: <SignOutModal closeModal={() => setModal(undefined)} />,
  };

  const setErrorData = useOnError();
  const onSuccess = (data: HeaderDTO) => {
    setHeader?.(data);
  };
  const onError = (error: AxiosError<string>) => {
    setErrorData(error);
  };
  const { refetch: refreshHeaderData } = useGet_HeaderData(onSuccess, onError);

  useGet_AccountSettings((accountSettings: AccountSettingsDTO) => setAccountSettings?.(accountSettings), setErrorData);
  useGetNotificationTypes({
    onSuccess: (data) => {
      setNotificationTypes!(data);
    },
  });

  const handlePlantSelection = (e: React.MouseEvent<HTMLDivElement>) => {
    const getPlantId = e.currentTarget.getAttribute("data-id");

    if (data.user.plants.length > 0) {
      if (getPlantId) {
        const newParams = { plantId: getPlantId };
        sessionStorage.setItem("plantId", getPlantId);
        setPlantIDParams(newParams);
        setParamsPlantID(parseInt(getPlantId));
        const selectedP1 = data.user.plants.filter((plant) => plant.plantId === parseInt(getPlantId));
        queryClient.clear();

        if (selectedP1.length > 0) {
          setSelectedPlant?.({
            isDefault: true,
            plantId: selectedP1[0].plantId,
            baseOrgId: selectedP1[0].baseOrgId,
            name: selectedP1[0].name
          });
        }
      }
    }
  };
  useEffect(() => {
    let selectedP: typeof data.user.plants = [];
    if (data.user.plants.length > 0 && data.user.plants[0].baseOrgId > 0) {
      if (plantIDParams.get("plantId")) {
        selectedP = data.user.plants.filter((plant) => plant.plantId === parseInt(plantIDParams.get("plantId") || ""));
      }
      if (selectedP.length === 0 && sessionStorage.getItem("plantId")) {
        selectedP = data.user.plants.filter((plant) => plant.plantId === parseInt(sessionStorage.getItem("plantId") || ""));
      }
      if (selectedP.length === 0) {
        selectedP = data.user.plants.filter((plant) => plant.isDefault === true);
      }

      if (data.user.plants.length > 0 && selectedP.length === 0) {
        selectedP = [data.user.plants[0]];
      }

      if (selectedP.length > 0) {
        sessionStorage.setItem("plantId", `${selectedP[0].plantId}`);
        setSelectedPlant?.({
          isDefault: true,
          plantId: selectedP[0].plantId,
          baseOrgId: selectedP[0].baseOrgId,
          name: selectedP[0].name,
        });
        setParamsPlantID(selectedP[0].plantId);
        setSelectedOption(selectedP[0].name);
      }
    }
  }, [data, plantIDParams, setSelectedPlant]);

  useEffect(() => {
    setShowError?.({
      ErrorType: null,
      isError: false,
      title: "",
    });
  }, [selectedPlant, setShowError]);

  useEffect(() => {
    const currentParams = plantIDParams.get("plantId");
    if (currentParams) {
      setParamsPlantID(parseInt(currentParams));
    }
  }, [paramsPlantID, plantIDParams]);

  usePlantConfigData((data: any) => {
    setPlantConfig?.(data);
  }, setErrorData);

  // when the location changes, refresh the header data (in order to get a new list of notifications)
  useEffect(() => {
    refreshHeaderData();
  }, [location, refreshHeaderData]);
  
  let desktopHeaderContent: ReactElement = <></>;
  if (isDesktop) {
    desktopHeaderContent = (
      <div className="nav-item">
        <SearchGlobal />
        <div className="plant-select">
          <Dropdown
            dropdowntype={""}
            options={data.user.plants}
            handleDropDownSelect={handlePlantSelection}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption!}
            isPlantSelection={true}
          />
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="navbar">
        <div className="nav-item">
          <Link to={'/'} className="link_text">
            <img src={logo} className="App-logo ml-0 lg:ml-6" alt="logo" />
          </Link>
        </div>
        {desktopHeaderContent}
        <div className="nav-item gap-2">
          {isDesktop && (
            <div className="initials" onClick={() => setShowAccountMenu(!showAccountMenu)}>
              <img src={data.user.pictureUrl} alt="" className="hover:cursor-pointer" />
              <Tooltip shown={showAccountMenu} style={{}} backdrop={true}>
                <div className={`text-black font-normal text-sm m-4 flex flex-col min-w-[300px]`}>
                  <div className="flex justify-between items-center mb-4">
                    <div>Customer Portal</div>
                    <div>
                      <span onClick={() => setModal(modals.signOut)} className="font-normal text-dark-blue hover:text-dark-blue-30 hover:cursor-pointer">
                        Sign Out
                      </span>
                    </div>
                  </div>

                  <hr className="m-0 mb-4" />

                  <div className="flex gap-3">
                    <div className="initials-full">
                      <img src={data.user.pictureUrl} alt="User Profile Pic" />
                    </div>
                    <div className="flex flex-col gap-1.5">
                      <div className="text-lg font-bold">
                        {accountSettings.fName} {accountSettings.lName}
                      </div>
                      <div>{accountSettings.email}</div>
                      <div>
                        <Link to={'/settings'} className="font-normal text-dark-blue hover:text-dark-blue-30 hover:cursor-pointer">
                          View Account Settings
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Tooltip>
            </div>
          )}
          <div className="nav__action_wrp">
            {/* cant test locally but the 'goal' here is to not show the defaultPlantId notifications as the correct call is made and data retrieved             */}
            <Notification innerSpacingZero={false} value={selectedPlant?.plantId > 0 ? data.newNotificationCount : 0}>
              <div className="nav__action mr-4 lg:mr-0">
                <img src={bellIcon} className="bell-icon" alt="logo" />
              </div>
            </Notification>
            {!isDesktop && (
              <div
                className="nav__action"
                onClick={() => {
                  toggleMobileNav?.(!mobileNavToggle);
                }}
                style={{ cursor: 'pointer' }}
              >
                <img src={menuOpenIcon} alt="open menu" />
              </div>
            )}
          </div>
        </div>
      </div>
      {!isDesktop && (
        <div className="plant-select">
          <Dropdown dropdowntype={''} className="mobile-dropdown" options={data.user.plants} handleDropDownSelect={handlePlantSelection} selectedOption={selectedOption} setSelectedOption={setSelectedOption!} isPlantSelection={true} />
        </div>
      )}
      {!isDesktop && <MobileMenu />}
      {modal}
    </>
  );
}

interface NavbarProps {
  headerHeight?: number;
}

export default Navbar;
