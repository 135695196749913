import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Store } from "../../../../Store";
import Accordion from "../../../../components/Accordian/Accordion";
import Button from "../../../../components/Button/Button";
import FormSuccess from "../../../../components/FormsPages/FormSuccess/FormSuccess";
import { useOnError } from "../../../../customHooks/useOnError";
import { NotificationGroup, NotificationTrigger } from "../../../../utils/interface";
import ModuleNotificationTriggers from "./ModuleNotificationTriggers";
import { useGetEmailTriggers, useGetNotificationTriggers, useUpdateEmailAlertTriggers, useUpdateNotificationTriggers } from "./NotificationManagementHttpServices";

function NotificationManagementBody() {
	const {
		showError: error,
		module: { groups },
	} = useContext(Store);

	const { groupId } = useParams();
	let defaultPanelIndex = 'Plant Information';
	const [openedPanelIndex, setOpenedPanelIndex] = useState<number|string>(defaultPanelIndex);
	const [currentPanelIndex, setCurrentPanelIndex] = useState<number|string>(defaultPanelIndex);
	const [notificationTriggers, setNotificationTriggers] = useState<Array<NotificationTrigger>>();
	const [notificationTriggerForm, setNotificationTriggerForm] = useState<Array<NotificationTrigger>>();
	const [notificationGroups, setNotificationGroups] = useState<Array<NotificationGroup>>([]);
	const [canSave, setCanSave] = useState<boolean>(true);
	const [currentGroupId, setCurrentGroupId] = useState<string>(groupId || 'notification-settings');

	const setErrorData = useOnError();
	const { refetch: refetchNotificationTriggers, data: fetchNotificationTriggers, isFetched: isFetchedNotifications } = useGetNotificationTriggers();
	const { refetch: refetchEmailTriggers, data: fetchEmailTriggers, isFetched: isFetchedEmails } = useGetEmailTriggers();
	const { mutate: UpdateNotioficationTriggers, isSuccess: isUpdatedNotificationTriggers, reset: resetUpdateNotificationTriggers } = useUpdateNotificationTriggers();
	const { mutate: UpdateEmailAlertTriggers, isSuccess: isUpdatedEmailAlertTriggers, reset: resetUpdateEmailAlertTriggers } = useUpdateEmailAlertTriggers();

	useEffect(() => {
		setOpenedPanelIndex(currentPanelIndex);
	}, [currentPanelIndex]);

	useEffect(() => {
		switch (groupId) {
			case "notification-settings":
				setNotificationTriggers(fetchNotificationTriggers?.data);
				setNotificationTriggerForm(fetchNotificationTriggers?.data);
				
				break;
			case "email-alert-settings":
				setNotificationTriggers(fetchEmailTriggers?.data);
				setNotificationTriggerForm(fetchEmailTriggers?.data);
				
				break;
		}
	}, [fetchNotificationTriggers, fetchEmailTriggers]);

	useEffect(() => {
		setOpenedPanelIndex(-1);
		if ((groupId && groupId !== currentGroupId) ) {
			
			setCurrentGroupId(groupId || '');
			(async () => {
				try {
					switch (groupId) {
						case "notification-settings":
							const refetchNotificationTriggerData = await refetchNotificationTriggers();
							setNotificationTriggers(refetchNotificationTriggerData?.data?.data);
							setNotificationTriggerForm(refetchNotificationTriggerData?.data?.data);
							
							break;
						case "email-alert-settings":
							const refetchEmailTriggerData = await refetchEmailTriggers();
							setNotificationTriggers(refetchEmailTriggerData?.data?.data);
							setNotificationTriggerForm(refetchEmailTriggerData?.data?.data);
							
							break;
					}
					setCurrentPanelIndex(openedPanelIndex);
				} catch (error) {
					setErrorData(error);
				}
			})();
		}
		setOpenedPanelIndex(defaultPanelIndex);
	}, [groupId, currentGroupId, notificationTriggers]);

	useEffect(() => {
		const formatNotificationGroups: any = [...new Map(notificationTriggers
			?.sort((a, b) => (a.parentModuleId > b.parentModuleId ? 1 : -1))
			?.map((item) => {
				
				let childGroups = notificationTriggers.filter(trigger => (trigger.parentModuleId === item.parentModuleId) || (item.moduleName === 'Plant Information' || item.moduleName === 'Issue Reports'));
	
				let childNotifications = childGroups
					?.sort((a, b) => (a.moduleId > b.moduleId ? 1 : -1))
					?.reduce((childmodule: any, mod: NotificationTrigger) => {
						if (!item.parentModuleId) {
							item.parentModuleId = item.moduleId;
							item.parentModuleName = item.moduleName;

							(childmodule[item.moduleName] = childmodule[item.moduleName] || []).push(mod);
						}
						(childmodule[mod.moduleName ?? 'Other'] = childmodule[mod.moduleName ?? 'Other'] || []).push(mod);
					
					return childmodule;
				}, {})
	
				return 	[item["parentModuleId"], {id: item.parentModuleId, name: item.parentModuleName, modules: childNotifications}];
				
			}
		)).values()].filter(item => item.id > 0)?.sort((a, b) => (a.id > b.id ? 1 : -1));

		setNotificationGroups(formatNotificationGroups);
	}, [notificationTriggers]);

	useEffect(() => {
		const checkTriggerChanges = JSON.stringify(notificationTriggers) !== JSON.stringify(notificationTriggerForm);

		setCanSave(checkTriggerChanges);
	}, [notificationTriggerForm, notificationTriggers]);
	
	const updateNotificationTriggers = async () => {
		if (notificationTriggerForm) {
			try {
				switch (groupId) {
					case "notification-settings":
						UpdateNotioficationTriggers(notificationTriggerForm, {
							onSuccess: (data) => {
								setNotificationTriggers(data.data);
							  },
							  onError: (error:unknown) => {
								setErrorData(error);
							  }
						});
						break;
					case "email-alert-settings":
						UpdateEmailAlertTriggers(notificationTriggerForm, {
							onSuccess: (data) => {
								setNotificationTriggers(data.data);
							  },
							  onError: (error:unknown) => {
								setErrorData(error);
							  }
						});
						break;
				}
			} catch (error) {
				setErrorData(error);
			}
		}
	};

	const handleCancelChanges = () => {
		setNotificationTriggerForm(notificationTriggers);
	}

	const executeScroll = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	return (
    <div style={{ flexGrow: 1 }}>
      {(isUpdatedNotificationTriggers || isUpdatedEmailAlertTriggers) && (
        <FormSuccess
          modaltitlename={'Changes Saved'}
          modaltitle={''}
          comment={`${isUpdatedNotificationTriggers ? 'Notification' : 'Email Alert'} Settings have been successfully updated.`}
          isTitleCenter={true}
          modalSize={'sm'}
          button_done={true}
          buttonDoneOnclick={() => {
            resetUpdateNotificationTriggers();
            resetUpdateEmailAlertTriggers();
            executeScroll();
          }}
          button_addmore={false}
        />
      )}
      {notificationTriggers &&
        notificationGroups &&
        notificationGroups.map((group, groupIndex) => (
          <div key={groupIndex}>
            <>
              <div className="flex flex-row mt-5 font-bold text-lg text-dark-blue">{group.name}</div>

              {Object.keys(group.modules).map((module, moduleIndex) => {
                return (
                  <div key={module}>
                    <Accordion isDeleted={false} titleContent={`${module}`} isOpen={openedPanelIndex === module} toggleOpen={() => setOpenedPanelIndex(openedPanelIndex !== module ? module : -1)} headerStyle={{ borderRadius: 4, marginTop: 20, paddingLeft: 20, fontWeight: 'bold' }} contentStyle={{}}>
                      <ModuleNotificationTriggers moduleNotificationTriggers={(notificationTriggerForm && notificationTriggerForm.filter((t) => t.moduleName === module)) || []} notificationTriggers={notificationTriggerForm || []} setNotificationTriggers={setNotificationTriggerForm} />
                    </Accordion>
                  </div>
                );
              })}
            </>
          </div>
        ))}

      <hr />
      <div style={{ display: 'flex', gap: 10 }}>
        <Button className="darkBlue" text={'Save Changes'} disabled={!canSave} onClick={updateNotificationTriggers}></Button>
        <Button className="whiteBtn" text={'Cancel'} disabled={!canSave} onClick={handleCancelChanges}></Button>
      </div>
    </div>
  );
}

export default NotificationManagementBody;
