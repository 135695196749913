function NotificationHeader() {
  return (
    <div style={{ justifyContent: 'space-between' }} className={`flex flex-row gap-6 `}>
      <div className={`flex flex-col gap-6`}>
        <div className={'font-bold text-2xl'}>Email Alert & Notifications Management</div>
        <div className={`flex flex-col gap-2`}>
          <div className={'text-[font-size:var(--fs14px)]'}>Manage the default in-app notifications and email alert settings for all Customer Portal users.</div>
          <div className={'text-[font-size:var(--fs14px)]'}>Default Settings will apply to new users and those who haven't customized their notification and email preferences.</div>
        </div>
      </div>
    </div>
  );
}

export default NotificationHeader;
