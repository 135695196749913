import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Store } from "../../Store";
import PlantSiteDetails from "../../components/PlantSiteDetails/PlantSiteDetails";
import SectionCard from "../../components/SectionCard/SectionCard";
import IssueReports from "../../components/IssueReports/IssueReports";
import PartsNSerivcesRequest from "../../components/PartNServices/PartNServicesRequest";
import SupportTile from "../../components/SupportTile/SupportTile";
import TechnicalDoc from "../../components/TechnicalDoc/TechnicalDoc";
import ResourcesTile from "../../components/ResourcesTile/ResourcesTile";
import Footer from "../../components/Footer/Footer";
import "./Home.css";
import ErrorCard from "../../components/ErrorCard/ErrorCard";
import { useSelectedPlantDetails } from "../PlantInformation/DashboardHttpServices";
import { PlantSiteDetailsDTO } from "../../utils/interface";
import { useOnError } from "../../customHooks/useOnError";
import { useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { emailOptOut, useGetIsOptedOut } from "../../customHooks/UserHttpServices";
import Alert from "../../components/Alert/Alert";
import AccountSettingsModal from "../../components/Navbar/AccountSettingsModal";
import { useQueryClient } from "react-query";
import { queryKeys } from "../../react-query/queryKeys";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

interface HomeProps {
  isEmailOptOut: boolean
}

function Home({isEmailOptOut}: HomeProps) {
  const {
    header: {
	  data, 
      data: {
        user: { firstName, plants },
      },
    },
    showError,
    selectedPlant,
    setShowError,
    setSelectedPlantDetails,
    setSelectedPlant,
  } = useContext(Store);

  const [showAccountSettingsModal, setShowAccountSettingsModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string|ReactElement>();

  const setErrorData = useOnError();
  const queryClient = useQueryClient()
  const handleRefresh = () => {
    window.location.reload();
  };

  let location = useLocation();

  // Test App Insights
  const appInsights = useAppInsightsContext();
  const trackEvent = useTrackEvent(appInsights, 'UI: HomePage', {msg: ''});
  
  ///////////////////////////////////////////
  // Fetch useApiSelectedPlantDetails Start
  ///////////////////////////////////////////
  const { refetch: refetchPlantDetails } = useSelectedPlantDetails((plantSiteDetails: PlantSiteDetailsDTO) => {
    if (setSelectedPlantDetails) {
      setSelectedPlantDetails({
        ...plantSiteDetails,
        data: {
          ...plantSiteDetails.data,
          plantId: selectedPlant.plantId
        }
      })
    }
  }, setErrorData);
  ///////////////////////////////////////////
  // Fetch useApiSelectedPlantDetails End
  ///////////////////////////////////////////

  // should refresh store selected plant if plantID in URL on load or if URL param changes
  useEffect(() => {
    const checkUrlForRightSearchParam = location?.search?.includes('plantid');
    const plantByUrl = checkUrlForRightSearchParam && +location.search.split('=')[1];
    const plantInList = plants?.filter(plant => plant.plantId === plantByUrl)[0];
    if (!!plantByUrl && plantInList) {
      setSelectedPlant && setSelectedPlant({
        isDefault: true,
        plantId: plantByUrl,
        baseOrgId: plantInList.baseOrgId,
        name: plantInList.name,
      });
      sessionStorage.setItem("plantId", plantByUrl.toString());
      refetchPlantDetails();
    };
  }, [location, plants, refetchPlantDetails, setSelectedPlant]);

  
  const {mutate: optOutOfEmails} = useMutation(emailOptOut, {onSuccess: () => {
    queryClient.invalidateQueries(queryKeys.accountSettings);
    setSuccessMessage(<>You have successfully opted out of receiving all email alerts. If this was a mistake or if you&apos;d like to manage these settings in the future, you can do so in the Account &amp; Settings menu. <span style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }} onClick={() => setShowAccountSettingsModal(true)}>Manage Email Alerts</span></>)
  }});

  const {data: isOptedOut, isFetched} = useGetIsOptedOut({
    enabled: isEmailOptOut
  });
  
  useEffect(() => {
    if (isEmailOptOut && isFetched && isOptedOut === false) {
      optOutOfEmails({});
    }
  }, [isEmailOptOut, isFetched, isOptedOut]);

  useEffect(() => {
    if (location.state && location.state.successMessage) {
      setSuccessMessage(location.state.successMessage);
    }
  }, [location.state]);

  useEffect(() => {
    trackEvent({msg: 'Test App Insights: Home Page Loaded'});
  }, [trackEvent]);

  return (
    <div className="column flex-grow-1">
      <div className="content">
        <div className="login-header">
          Welcome to Customer Portal,{" "}
          {firstName.length > 12
            ? firstName.substring(0, 12) + "..."
            : firstName}
        </div>
        {successMessage && (
          <div className="mb-2">
            <Alert type="success" onClose={() => setSuccessMessage(undefined)}>
              <span>
                {successMessage}
              </span>
            </Alert>
          </div>
        )}
        {showError.title &&
        <ErrorCard
          ErrorMessage={showError.title}        
          onRefresh={handleRefresh}
          ErrorType={showError.ErrorType}
        />}
        <PlantSiteDetails overview={true} />
        <div className="card issue_prt_tech_wrp section-cards">
          <SectionCard>
            <IssueReports />
          </SectionCard>
          <SectionCard>
            <PartsNSerivcesRequest />
          </SectionCard>
          <SectionCard>
            <TechnicalDoc />
          </SectionCard>
        </div>
        <div className="section-header">Support</div>
        <SupportTile />
        <div className="section-header">Mitsubishi Power Resources</div>
        <div className="card section-cards">
          <SectionCard>
            <ResourcesTile id={"tomoni"} />
          </SectionCard>
          <SectionCard>
            <ResourcesTile id={"takasago"} />
          </SectionCard>
          <SectionCard>
            <ResourcesTile id={"userGroup"} />
          </SectionCard>
        </div>
      </div>
      <div className="content content-footer flex-grow-0 flex-end">
        <Footer />
      </div>
	  {showAccountSettingsModal && <AccountSettingsModal headerData={data} closeModal={() => setShowAccountSettingsModal(false)} />}
    </div>
  );
}

export default Home;

Home.defaultProps = {
  isEmailOptOut: false
};