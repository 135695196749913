import { MouseEvent, ReactElement, useCallback, useMemo, useState } from 'react';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import classes from './SettingsModuleContainer.module.css';
import { SettingsModuleInterface, SettingsOutletModuleInterface } from './SettingsContainer';
import { NotificationSettingOption, SelectOptionProps } from '../../utils/interface';
import useWindowSize from '../../customHooks/useWindowSize';
import { useGetNotificationOptions } from './Notifications/UserNotificationSettingsHttpServices';
import Loader from '../../components/Loader/Loader';
import Dropdown from '../../components/Dropdown/Dropdown';


export interface ModuleGroupOptionInterface {
  id: string;
  name: string;
  route: string;
}

const SettingsModuleContainer = () => {
  const { module, modules } = useOutletContext<SettingsOutletModuleInterface>();
  const { isMobile, isTablet } = useWindowSize();
  const { moduleName: moduleRoute } = useParams();
  const [ notificationOptions, setNotificationOptions ] = useState<Array<NotificationSettingOption>>();
  const [ selectedOption, setSelectedOption ] = useState<string | null>(module.tabListTitle || null);
  
  const { moduleBodyContainer: SettingsContent } = module;
  const navigate = useNavigate();

  const { isFetching, isLoading } = useGetNotificationOptions({
    onSuccess(data) {
      setNotificationOptions(data?.data || []);
    },
    onError(err: unknown) {
      console.log(err);
    }
  });

  const dropdownOptions: SelectOptionProps[] = useMemo(() => {
    return modules.map((s, i) => ({
      id: s.id,
      name: s.tabListTitle as string
    }));
  }, [modules]);

  const handleSectionSelection = useCallback((e: MouseEvent<HTMLDivElement>, option: SelectOptionProps) => {
    const selectedModule = modules.find((module) => module.id === option.id);
    if (selectedModule) {
      navigate(`../${selectedModule.route}`);
    }
  }, [modules, navigate]);

  let listGroupSelection: ReactElement = <></>;
  if (modules) {
    if (isMobile || isTablet) {
      listGroupSelection = (
        <div className={classes.flex_column}>
          <div className="borderDropdown">
            <Dropdown         
              style={{width:'100%'}}  
              handleDropDownSelect={handleSectionSelection}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              options={dropdownOptions}
            />
          </div>
        </div>
      );
    } else {
      listGroupSelection = (
        <div className={`${classes.flex_column} ${classes.module_list}`}>
          {Array.isArray(modules) &&
            (modules as Array<SettingsModuleInterface>).map((listGroupItem, i) => (
              <Link
                className={`${classes.document_group_link} ${moduleRoute === listGroupItem.route ? classes.active : ''}`}
                key={i}
                to={`../${listGroupItem.route}`}
              >
                {listGroupItem.tabListTitle}
              </Link>
            ))}
        </div>
      );
    }
  }

  return (
    <>
      <div className={`${classes.top_section} ${classes.flex_column}`}>{module.moduleHeaderContent}</div>
      <div className={`${classes.line}`} />
      <div className={`${isMobile || isTablet ? classes.flex_column : classes.flex_row} ${classes.module_information}`}>
        {listGroupSelection}
        {isLoading || isFetching ? (
          <Loader />
        ) : (
          <>
            {notificationOptions && notificationOptions.length > 0 && (
              <SettingsContent
                module={module}
                modules={modules}
                notificationOptions={notificationOptions}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SettingsModuleContainer;
