import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ListGroup, ListModuleInterface, NotificationSettingOption, SiteUnit } from '../../utils/interface';
import SettingsHeader from './SettingsHeader';
import { MainContainer } from '../MainContainer';
import UserNotificationSettingsBody from './Notifications/UserNotificationSettingsBody';
import DefaultPlantSettingsBody from './DefaultPlant/DefaultPlantSettingsBody';

export interface SettingsModuleInterface {
  id: number;
  name: string;
  route: string;
  addEditHeaderName: string;
  addModalHeaderContent: string[];
  moduleHeaderContent: React.ReactElement | string;
  moduleBodyContainer: React.FunctionComponent<SettingsContentProps>;
  headerTitle?: string;
  headerText?: string;
  tabListTitle?: string;
}

export interface SettingsContentProps {
  module: SettingsModuleInterface;
  modules: SettingsModuleInterface[];
  notificationOptions: NotificationSettingOption[];
}

const modules: SettingsModuleInterface[] = [
  {
    id: 1,
    name: 'Account Settings',
    route: 'default-plant',
    headerTitle: 'Default Plant Settings',
    headerText: 'Select your default plant. This will be the plant automatically displayed when you login to the Customer Portal.',
    tabListTitle: 'Default Plant  Settings',
    addEditHeaderName: '',
    moduleHeaderContent: <SettingsHeader />,
    moduleBodyContainer: DefaultPlantSettingsBody,
    addModalHeaderContent: []
  },
  {
    id: 2,
    name: 'Account Settings',
    route: 'notifications',
    addEditHeaderName: '',
    tabListTitle: 'Notification Settings',
    headerTitle: 'In-App Notification & Email Settings',
    headerText: 'Choose which email and in-app notifications you would like to receive. Please note that these settings will only apply to the currently selected plant, while email opt-out will turn off email alerts for all plants assigned to you.',
    moduleHeaderContent: <SettingsHeader />,
    moduleBodyContainer: UserNotificationSettingsBody,
    addModalHeaderContent: []
  }
];

export interface AddDocumentRequiredProps {
  onSave: (data: any) => void;
  groups: ListGroup[];
  siteUnits: SiteUnit[];
  module?: ListModuleInterface;
  isEdit: boolean;
}

export interface SettingsOutletModuleInterface {
  modules: SettingsModuleInterface[];
  module: SettingsModuleInterface;
  notificationOptions: NotificationSettingOption[];
}

interface SettingsContainerProps {}

function SettingsContainer(props: SettingsContainerProps) {
  const [module, setModule] = useState<SettingsModuleInterface | null>(null);
  const { moduleName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const module = modules.find((module) => module.route === moduleName);
      if (module) {
        setModule?.(module);
      } else {
        navigate(modules[0].route);
      }
    })();
  }, [moduleName, setModule, navigate]);

  return (
    <>
      {module && module.name.length > 0 && (
        <MainContainer
          breadCrumbs={['Settings', module.name]}
          title={`${module.name}`}
        >
          <Outlet context={{ modules, module }} />
        </MainContainer>
      )}
    </>
  );
}

export default SettingsContainer;
