import React, { ReactElement, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link, generatePath, useLocation } from "react-router-dom";
import { Store } from "../../Store";
import AccountSettingsModal from "../../components/Navbar/AccountSettingsModal";
import SignOutModal from "../../components/Navbar/SignOutModal";
import { useIsInternalAdmin } from "../../customHooks/useIsInternalAdmin";
import { useIsInternalOrExternal } from "../../customHooks/useIsInternalOrExternal";
import ImageImports from "../../utils/ImageImports";
import "./MobileMenu.css";
import MobileMenuHeader from "./MobileMenuHeader/MobileMenuHeader";
import MobileSubmenuLink from "./MobileSubmenuLink/MobileSubmenuLink";

const { homeBlue, plantBlue, documentWarnBlue, partsNservices, documentSearch, support, privacy_icon } = ImageImports;

interface mobileRouteObject {
  id: string;
  title: string;
  path: string;
  icon: string;
  cName: string;
  subRoutes?: Array<{ route: string; title: string; requiresAdmin?: boolean; requiresInternal?: boolean }>;
  requiresAdmin?: boolean;
}
const mobileMenuRoutes: mobileRouteObject[] = [
  {
    id: "home",
    title: "Home",
    path: "/",
    icon: homeBlue,
    cName: "mobile-nav-links"
  },
  {
    id: "plantSite",
    title: "Plant Information",
    path: "/plant-information",
    icon: plantBlue,
    cName: "mobile-nav-links"
  },
  {
    id: "issueReport",
    title: "Issue Reports",
    path: "/issue-reports",
    icon: documentWarnBlue,
    cName: "mobile-nav-links"
  },
  {
    id: "partNServ",
    title: "Parts & Service",
    path: "",
    icon: partsNservices,
    cName: "mobile-nav-links",
    subRoutes: [
      // { route: generatePath('/parts&service/:moduleName', {moduleName: 'Parts & Service'}), title: "Parts & Service"},
      { route: generatePath("/parts&service/:moduleName", { moduleName: "QuoteRequests" }), title: "Quote Requests" },
      { route: generatePath("/parts&service/:moduleName", { moduleName: "Parts Catalog" }), title: "Parts Catalog" },
      { route: generatePath("/parts&service/:param", { param: "customer-survey" }), title: "Voice of Customer Responses", requiresInternal: true }
    ]
  },
  {
    id: "techDoc",
    title: "Technical Documentation",
    path: "",
    icon: documentSearch,
    cName: "mobile-nav-links",
    subRoutes: [
      { route: generatePath("/technical-documentation/:moduleName", { moduleName: "Mitsubishi Power Reports" }), title: "Mitsubishi Power Reports" },
      { route: generatePath("/technical-documentation/:moduleName", { moduleName: "Technical Bulletins" }), title: "Technical Bulletins" },
      { route: generatePath("/technical-documentation/:moduleName", { moduleName: "O&M Manuals" }), title: "O&M Manuals" }
    ]
  },
  {
    id: "support",
    title: "Support",
    path: "",
    icon: support,
    cName: "mobile-nav-links",
    subRoutes: [
      { route: generatePath("/support/esr/:supportModuleName", { supportModuleName: "Engineering Support Requests" }), title: "Engineering Support Requests" },
      { route: generatePath("/support/faq/:supportModuleName", { supportModuleName: "Frequently Asked Questions" }), title: "Frequently Asked Questions" }
    ]
  },
  {
    id: "administration",
    title: "Administration",
    path: "",
    icon: privacy_icon,
    cName: "mobile-nav-links",
    subRoutes: [
      // {route: generatePath('/support/:supportModuleName', {supportModuleName:"Support"}), title: "Support"},
      { route: "/administration/organization-management", title: "Organization Management" },
      { route: generatePath("/administration/:param", { param: "Plant-Management" }), title: "Plant Management" },
      { route: generatePath("/administration/:param", { param: "Role-Configuration" }), title: "Role Configuration" },
      { route: generatePath("/administration/:param", { param: "Email-Alert-&-Notification-Management" }), title: "Email Alert & Notification Management" },
      { route: generatePath("/administration/:param", { param: "user-management" }), title: "User Management" },
      { route: generatePath("/administration/:param", { param: "data-reporting" }), title: "Data & Reporting" }
    ],
    requiresAdmin: true
  }
];

function MobileMenu() {
  const navHeaderHeight = useRef<HTMLDivElement>(null);
  const [navScollHeight, setNavScollHeight] = useState(0);
  const stateContext = useContext(Store);
  const {
    accountSettings: { data: accountSettings },
    header: { data }
  } = useContext(Store);

  const [modal, setModal] = useState<ReactElement | undefined>();

  const isInternalAdmin = useIsInternalAdmin();
  const adminFilter = useCallback(
    ({ requiresAdmin }: { requiresAdmin?: boolean }): boolean => {
      if (requiresAdmin === undefined || requiresAdmin === false) {
        return true;
      }
      return isInternalAdmin;
    },
    [isInternalAdmin]
  );

  const isInternalOrExternal = useIsInternalOrExternal();
  const internalFilter = useCallback(
    ({ requiresInternal }: { requiresInternal?: boolean }): boolean => {
      if (requiresInternal === undefined || requiresInternal === false) {
        return true;
      }
      return isInternalOrExternal;
    },
    [isInternalOrExternal]
  );

  const location = useLocation();

  const modals = {
    account: <AccountSettingsModal headerData={data} closeModal={() => setModal(undefined)} />,
    signOut: <SignOutModal closeModal={() => setModal(undefined)} />
  };

  useEffect(() => {
    let navh = window.innerHeight;
    let navhh = navHeaderHeight.current && navHeaderHeight.current.clientHeight;
    if (navh && navhh) {
      setNavScollHeight(navh - navhh);
    }
  }, [navScollHeight]);

  const { mobileNavToggle, toggleMobileNav } = stateContext;

  return (
    <div className={mobileNavToggle ? 'sidebar-mobile-wrapper active' : 'sidebar-mobile-wrapper'}>
      <div ref={navHeaderHeight}>
        <MobileMenuHeader />
      </div>
      <nav style={{ height: navScollHeight - 30 }}>
        {mobileMenuRoutes.filter(adminFilter).map((menuItem, index) => {
          if (menuItem.subRoutes) {
            return <MobileSubmenuLink adminFilter={adminFilter} internalFilter={internalFilter} index={index} key={menuItem.id} {...menuItem} />;
          }

          return (
            <div className="mobile-nav-item" key={menuItem.id}>
              <Link
                to={menuItem.path}
                className={location.pathname === menuItem.path ? 'active-menu mobile-nav-links' : 'mobile-nav-links'}
                onClick={() => {
                  toggleMobileNav && toggleMobileNav(!mobileNavToggle);
                }}
              >
                <div className="menu-icon">
                  <img src={menuItem.icon} alt={menuItem.title} />
                </div>
                <div>{menuItem.title}</div>
              </Link>
            </div>
          );
        })}
        <hr className="mobile-hr" />
        <div className={`text-black font-normal text-sm m-4 mobile-flex-column`}>
          <div className="mobile-flex-row justify-between items-center mb-6">
            <div>Customer Portal</div>
            <div>
              <span onClick={() => setModal(modals.signOut)} className="font-normal text-dark-blue hover:text-dark-blue-30 hover:cursor-pointer">
                Sign Out
              </span>
            </div>
          </div>

          <div className="mobile-flex-row gap-3">
            <div className="initials-full">
              <img src={data.user.pictureUrl} alt="User Profile Pic" />
            </div>
            <div className="mobile-flex-column gap-1.5">
              <div className="text-lg font-bold">
                {accountSettings.fName} {accountSettings.lName}
              </div>
              <div>{accountSettings.email}</div>
              <div>
                <Link
                  to={'/settings'}
                  className="font-normal text-dark-blue hover:text-dark-blue-30 hover:cursor-pointer"
                  onClick={() => {
                    toggleMobileNav && toggleMobileNav(!mobileNavToggle);
                  }}
                >
                  View Account Settings
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {modal}
    </div>
  );
}

export default MobileMenu;
