import { useOutletContext } from 'react-router-dom';
import { SettingsOutletModuleInterface } from './SettingsContainer';
import { AdminContactEmail } from '../../utils/globalVariables';

const SettingsHeader = () => {
  const outletContext = useOutletContext<SettingsOutletModuleInterface>();
  const { module } = outletContext;
  return (
    <>
      <div className={`flex flex-row gap-6 `}>
        <div className={`flex flex-col gap-6`}>
          <div className={'font-bold text-2xl'}>{module.headerTitle}</div>
          <div className={'gap-6 text-[font-size:var(--fs14px)]'}>{module.headerText}</div>
        </div>
      </div>
      <span className="block sm:inline">
        If you have any questions or difficulties updating your information,&nbsp;
        <a href={`mailto:${AdminContactEmail}`} className="no-underline text-ocean">
          <strong className="font-bold">Contact</strong>
        </a>
        &nbsp;the Customer Portal Administrator for assistance.
      </span>
    </>
  );
};

export default SettingsHeader;
