import { FunctionComponent, MouseEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SettingsContentProps } from '../SettingsContainer';
import { Store } from '../../../Store';
import ImageImports from '../../../utils/ImageImports';
import classes from './DefaultPlantSettingsBody.module.css';
import { SelectOptionProps } from '../../../utils/interface';
import Dropdown from '../../../components/Dropdown/Dropdown';
import Button from '../../../components/Button/Button';
import useWindowSize from '../../../customHooks/useWindowSize';
import { useUpdate_AccountSettings } from '../../PlantInformation/DashboardHttpServices';
import { useOnError } from '../../../customHooks/useOnError';


const DefaultPlantSettingsBody: FunctionComponent<SettingsContentProps> = ({ module }) => {
  const { pencil } = ImageImports;
  const { isDesktop, isTablet } = useWindowSize();
  const [ isEdit, setIsEdit ] = useState<boolean>(false);
  const setErrorData = useOnError();
  const {
    setAccountSettings,
    accountSettings: { data: accountSettings },
    header: {data: {user: { plants }}},
    setShowError
  } = useContext(Store);

  const [selectedOption, setSelectedOption] = useState<SelectOptionProps>();
  const plantOptions: SelectOptionProps[] = useMemo(() => {
    const plantList = [...new Set(plants?.map((i) => i.baseOrgId))];
    return [{ id: 0, name: 'Select a Default Plant' }, ...plantList.map((p) => ({ id: p, name: plants?.find((i) => i.baseOrgId === p)?.name as string }))];
  }, [plants]);

  useEffect(() => {
    setSelectedOption(plantOptions.find((x) => x.id === accountSettings.defaultBaseOrgId) || plantOptions[0]);
  }, [plantOptions, accountSettings.defaultBaseOrgId]);

  const handlePlantSelection = useCallback((e: MouseEvent<HTMLDivElement>, option: SelectOptionProps) => {
    setSelectedOption(plantOptions.find((x) => x.id === option.id) || plantOptions[0]);
  }, [plantOptions]);

  const handleCancel = () => {
    setIsEdit(false);
    setSelectedOption(plantOptions.find((x) => x.id === accountSettings.defaultBaseOrgId) || plantOptions[0]);
  };

  const { mutate: UpdateAccountSetting } = useUpdate_AccountSettings();
  const saveSettings = async () => {
    if (selectedOption && selectedOption.id > 0) {
      UpdateAccountSetting(
        {
          ...accountSettings,
          defaultBaseOrgId: selectedOption.id
        },
        {
          onSuccess(data) {
            setAccountSettings?.(data);
            setIsEdit(false);
            setShowError?.({
              isError: true,
              title: 'Your default plant has been successfully updated.',
              ErrorType: 'success'
            });
          },
          onError(error: unknown) {
            setErrorData(error);
          }
        }
      );
    } else {
      setShowError?.({
        isError: true,
        title: 'Invalid Plant Id Selected.',
        ErrorType: 'danger'
      });
    } 
  };

  return (
    <div className="flex col flex-1">
      <h3 className="flex flex-row font-bold mb-4 text-xl text-dark-blue">Manage Default Plant</h3>

      <div className={`flex ${isDesktop ? 'row' : 'col'} flex-1 gap-5`}>
        {isEdit ? (
          <div>
            <div className="borderDropdown">
              <Dropdown handleDropDownSelect={handlePlantSelection} selectedOption={selectedOption?.name || null} setSelectedOption={(option) => setSelectedOption(plantOptions.find((x) => x.name === option) || plantOptions[0])} options={plantOptions} />
            </div>
            <div className={`flex ${isDesktop || isTablet ? 'row' : 'col'} gap-3 mt-5`}>
              <Button className="darkBlue" text={'Save Changes'} disabled={!selectedOption || selectedOption?.id === 0 || selectedOption?.id === accountSettings.defaultBaseOrgId} onClick={saveSettings}></Button>
              <Button className="whiteBtn" text={'Cancel'} onClick={handleCancel}></Button>
            </div>
          </div>
        ) : (
          <div className="flex row gap-5 items-center">
            <div className="text-lg text-dark-blue opacity-90">{accountSettings?.defaultBaseOrgId && accountSettings?.defaultBaseOrgName ? accountSettings?.defaultBaseOrgName : 'Default Plant has not been set yet.'}</div>
            <div className={`${classes.edit_icon} mt-1 ${isEdit ? 'invisible' : 'visible'}`} onClick={() => setIsEdit(true)}>
              <img src={pencil} alt="Edit" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DefaultPlantSettingsBody;
