import { ComponentType, CSSProperties, FunctionComponent, ReactElement, RefObject, useEffect, useId, useRef, useState } from 'react';
import useWindowSize from '../../customHooks/useWindowSize';
import classes from './Tooltip.module.css';

interface TooltipProps {
  shown: boolean;
  children: React.ReactNode;
  style?: React.CSSProperties;
  backdrop?: boolean;
}

const Tooltip: FunctionComponent<TooltipProps> = ({ shown, style, backdrop, ...props }) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const { isMobile, isTablet } = useWindowSize();
  const [styles, setStyles] = useState<CSSProperties>({ right: 0 });

  useEffect(() => {
    if (shown && tooltipRef.current) {
      const rect = tooltipRef.current.getBoundingClientRect();
      setStyles({ left: '-242px' });

      if (rect.x - 10 < 0) {
        //setStyles(isMobile ? { left: "-12px", right: "-12px" } : { left: 0 })
        setStyles({ left: '-12px', right: '-12px' });
      }
    }
  }, [shown, isMobile, isTablet]);

  return (
    <>
      {shown && (
        <>
          {backdrop && <div className={classes.backdrop}></div>}
          <div
            ref={tooltipRef}
            {...props}
            className={classes.tooltip_menu}
            style={style || styles}
          >
            {props.children}
          </div>
        </>
      )}
    </>
  );
};

export default Tooltip;

interface WithToolTipProps {
  tooltipContent: ReactElement;
  forceClose?: any;
  toggleClickWatcher?: boolean;
  style?: React.CSSProperties;
}
export const withTooltip =
  <P extends object>(WrappedComponent: ComponentType<P>): FunctionComponent<P & WithToolTipProps> =>
  ({ tooltipContent, forceClose, toggleClickWatcher = false, style, ...props }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const id = useId().replaceAll(':', '_');
    const wrapperRef = useRef(null);

    function useOutsideAlerter(ref: RefObject<HTMLDivElement>) {
      useEffect(() => {
        function handleClickOutside({ target }: MouseEvent) {
          if (ref.current && !ref.current.contains(target as Node)) {
            setIsOpen(false);
          }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [ref]);
    }
    useOutsideAlerter(wrapperRef);

    useEffect(() => {
      setIsOpen(false);
    }, [forceClose]);

    return (
      <div
        id={id}
        ref={wrapperRef}
        className="has-tooltip"
        style={{ position: 'relative' }}
      >
        <WrappedComponent
          {...(props as P)}
          onClick={() => setIsOpen(!isOpen)}
        />
        <Tooltip
          shown={isOpen}
          style={style}
        >
          {tooltipContent}
        </Tooltip>
      </div>
    );
  };
