import axios, { AxiosResponse } from 'axios';
import { MutationFunction, useQuery, UseQueryOptions } from 'react-query';
import { notificationSettingsApiUrl } from '../../../endpoints/endpoints';
import { CPApiResponse, NotificationSettingOption, UserNotificationSetting } from '../../../utils/interface';
import { handleAxiosResponse } from '../../../utils/handleAxiosResponse';

interface UserNotificationSettingsCrudMethods<T1, T2> {
  getNotificationOptions: () => Promise<T1>;
  getUserNotifications: (baseOrgId: number) => Promise<T2>;
}

export const UserNotificationSettingsHttpServices: UserNotificationSettingsCrudMethods<CPApiResponse<Array<NotificationSettingOption>>, CPApiResponse<Array<UserNotificationSetting>>> = {
  getNotificationOptions: async (): Promise<CPApiResponse<Array<NotificationSettingOption>>> => {
    const response = await axios({
      url: `${notificationSettingsApiUrl}/Options`,
      method: 'GET'
    });

    return response.data;
  },
  getUserNotifications: async (baseOrgId: number): Promise<CPApiResponse<Array<UserNotificationSetting>>> => {
    const response = await axios({
      url: `${notificationSettingsApiUrl}/${baseOrgId}`,
      method: 'GET'
    });

    return response.data;
  }
};

declare type UpdateUserNotificationsParameters = {
  baseOrgId: number;
  userNotifications: UserNotificationSetting[];
};

export const updateUserNotifications: MutationFunction<Array<UserNotificationSetting>, UpdateUserNotificationsParameters> = async ({ baseOrgId, userNotifications }: UpdateUserNotificationsParameters) => {
  const response: AxiosResponse<CPApiResponse<Array<UserNotificationSetting>>> = await axios(`${notificationSettingsApiUrl}/${baseOrgId}`, {
    method: 'PUT',
    data: userNotifications
  });
  return handleAxiosResponse(response);
};

export const useGetUserNotifications = (baseOrgId: number, options: Omit<UseQueryOptions<CPApiResponse<Array<UserNotificationSetting>>>, 'queryKey' | 'queryFn'> = {}) => {
  return useQuery<CPApiResponse<Array<UserNotificationSetting>>>(['getUserNotifications', { baseOrgId }], () => UserNotificationSettingsHttpServices.getUserNotifications(baseOrgId), {
    ...options
  });
};

export const useGetNotificationOptions = (options: Omit<UseQueryOptions<CPApiResponse<Array<NotificationSettingOption>>>, 'queryKey' | 'queryFn'> = {}) => {
  return useQuery<CPApiResponse<Array<NotificationSettingOption>>>(['getNotificationOptions'], () => UserNotificationSettingsHttpServices.getNotificationOptions(), {
    ...options
  });
};
